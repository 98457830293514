class Point {
  constructor(options) {
    this.x = options.x;
    this.y = options.y;
    this.info = options.info;
    this.color = options.color;
    this.label = options.label;
    this.formattedValue = options.formatted;
  }

  get name() {
    return this.x;
  }

  export() {
    const result = { name: this.name, y: this.y };
    if (this.color) {
      result.color = this.color;
    }
    return result;
  }
}

class PiePoint extends Point {
  get name() {
    return this.label;
  }
}

class DateTimePoint extends Point {
  export() {
    return [this.x, this.y];
  }
}

export { Point, PiePoint, DateTimePoint };