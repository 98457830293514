import { createApp } from 'vue';
import axios from 'axios';
import VueScrollTo from 'vue-scrollto';
import { createPinia } from 'pinia';
import { captureException, setTag } from '@sentry/browser';
import VueScreen from '../plugins/screen';

import Tab from '../components/elements/tab.vue';
import Tabs from '../components/elements/tabs.vue';

axios.defaults.headers.common.Accept = 'application/json';

const VueAxios = {
  install: (app) => {
    app.config.globalProperties.$axios = axios;
  }
};

export default function (elementId, instanceOptions, options = {}) {
  document.addEventListener('turbolinks:load', () => {

    const element = document.getElementById(elementId);

    if (element != null) {
      axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      // IMPORTANT: create a clone of the options as otherwise it does not work
      // with turbolinks link navigation because Vue modifies this object -
      // it sets a "template" property and this is applied to the next initialization
      const app = createApp({ ...instanceOptions });

      // plugins
      app.use(VueAxios);
      app.use(VueScreen);
      app.use(createPinia());

      if (options.useVueScrollTo) {
        app.use(VueScrollTo, {
          duration: 400,
          force: true,
          onStart: () => {
            document.querySelector('body').classList.add('nav-transitioning');
          },
          onDone: (el) => {
            el.dispatchEvent(new Event('navDone'));
            document.querySelector('body').classList.remove('nav-transitioning');
          }
        });
      }

      if (APP_ENV === 'production') {
        app.config.errorHandler = (error, _, info) => {
          setTag('info', info);
          captureException(error);
        };
      }

      app.config.globalProperties.userSignedIn = document.querySelector('meta[name="user-signed-in"]').getAttribute('content') === 'true';

      // global components
      app.component('tabs', Tabs);
      app.component('tab', Tab);

      app.mount(`#${elementId}`);
    }
  });
};