import * as _ from 'lodash';
import Serie from './config/serie';
import Options from './config/options';

export default class {
  constructor(options) {
    this.options = new Options(options.options);
    this.serie = new Serie(options.series[0], 'pie');
  }

  getTitle() {
    return this.options.get('title');
  }

  shouldCenterTitle() {
    return !!this.options.get('title_options', {}).centered;
  }

  exportSerie() {
    return this.serie.export();
  }

  getLabel(index) {
    return this.serie.getPoint(index).label;
  }

  getFormattedValue(index) {
    return this.serie.getPoint(index).formattedValue;
  }

  hasData() {
    return _.some(this.serie.values(), (value) => value !== null && value !== 0);
  }
}
