<template lang="html">
  <div class="search form-autocomplete form-autocomplete-right" v-click-outside="onFocusOut">
    <div class="form-autocomplete-input has-icon-right">
      <input
          v-model="query"
          class="form-input search-input"
          :tabindex="tabindex"
          type="text"
          :placeholder="placeholderText"
          @focus="onFocusIn"
          @keyup.stop.prevent.enter="onEnter"
          @keydown.prevent.down="selectNextItem"
          @keydown.up.prevent="selectPrevItem"
          ref="searchInput">
      <i class="form-icon icon-search"></i>
      <i v-if="loading" class="form-icon loading mr-5"></i>
    </div>
    <ul class="menu" v-show="items.length && showResults">
      <li v-for="(item, index) in items" :key="item.key" class="menu-item"
          :class="{active: selectedIndex === index}">
        <slot name="item" v-bind="item"></slot>
      </li>
    </ul>
  </div>
</template>

<script>
import * as _ from 'lodash';
import ClickOutside from '../directives/click-outside';

export default {
  directives: {
    ClickOutside
  },
  props: {
    path: { type: String, required: true },
    tabindex: { type: Number, default: 0 },
    placeholder: { type: String, default: 'Search...' },
    shortPlaceholder: { type: String, default: 'Search...' }
  },
  data() {
    return {
      items: [],
      loading: false,
      query: '',
      inFocus: false,
      showResults: true,
      selectedIndex: 0
    };
  },
  computed: {
    hasItems() {
      return this.items.length > 0;
    },
    placeholderText() {
      return this.$screen.md && !this.inFocus ? this.shortPlaceholder : this.placeholder;
    }
  },
  watch: {
    query() {
      this.onQueryChangeDelayed();
    }
  },
  created() {
    this.onQueryChangeDelayed = _.debounce(this.onQueryChange, 300);
  },
  methods: {
    fetchResults() {
      return this.$axios.get(this.path, { params: { query: this.query } });
    },
    onEnter() {
      if (this.hasItems) {
        this.$emit('itemSelect', this.items[this.selectedIndex]);
      }
    },
    selectNextItem() {
      if (this.hasItems) {
        this.selectedIndex = this.selectedIndex + 1 < this.items.length ? this.selectedIndex + 1 : 0;
      }
    },
    selectPrevItem() {
      if (this.hasItems) {
        this.selectedIndex = this.selectedIndex === 0 ? this.items.length - 1 : this.selectedIndex - 1;
      }
    },
    onFocusIn() {
      this.inFocus = true;
      this.showResults = true;
      this.$emit('focused');
    },
    onFocusOut() {
      this.inFocus = false;
      this.showResults = false;
      this.$emit('unfocused');
    },
    onQueryChange() {
      this.selectedIndex = 0;
      if (!this.query) {
        this.items = [];
        return;
      }
      this.loading = true;
      this.fetchResults()
          .then((response) => {
            if (response.data) {
              if (response.data.length > 0 && !('key' in response.data[0])) {
                throw new Error('Key property must be present on each item in the response');
              } else {
                this.items = response.data;
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    clear() {
      this.query = '';
      this.items = [];
    },
    focus() {
      this.$refs.searchInput.focus();
    }
  }
};
</script>
