import { Point, PiePoint, DateTimePoint } from './point';

export default class {
  constructor(options, format = 'category') {
    this._options = options;
    this.id = options.id;
    this.name = options.name;
    this.format = format;
    this.points = options.points.map((pointOptions) => this.buildPoint(pointOptions))
  }

  buildPoint(options) {
    if (this.format === 'pie') {
      return new PiePoint(options);
    } else if (this.format === 'datetime') {
      return new DateTimePoint(options);
    }

    return new Point(options);
  }

  export() {
    const result = {
      name: this.name,
      id: this.id,
      data: this.points.map((point) => point.export())
    };

    if (this._options.color) result.color = this._options.color;
    if (this._options.type) result.type = this._options.type;

    return result;
  }

  values() {
    return this.points.map((point) => point.y);
  }

  getPoint(index) {
    return this.points[index];
  }
}
