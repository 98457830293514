import * as _ from 'lodash';
import Serie from './config/serie';
import PlotLine from './config/plot-line';
import Options from './config/options';
import Scrollbar from './config/scrollbar';

export default class {
  constructor(options, screen) {
    this._title = options.title;
    this.options = new Options(options.options);
    this._screen = screen;

    // convert the serie config data to Serie objects
    this._series = _.transform(options.series, (series, params) => series[params.id] = new Serie(params, this.getFormat()), {});
    this.scrollbar = new Scrollbar(this.options.get('scrollable', {}), this.getCategoryCount(), this._screen);
  }

  getFormat() {
    return this.options.get('format');
  }

  getTitle() {
    return this._title;
  }

  getHeight() {
    const heightOptions = this.options.get('height');
    if (!heightOptions) return null;

    return this._screen.findOptionForCurrentSize(heightOptions);
  }

  getSeries() {
    return Object.values(this._series);
  }

  getSerie(id) {
    return this._series[id];
  }

  getCategoryCount() {
    return this.getSeries()[0].points.length;
  }

  getHorizontalLines() {
    return this.options.get('horizontal_lines', []).map((lineOptions) => new PlotLine(lineOptions).export());
  }

  /**
   * Exports/converts the serie objects to a Highcharts format
   */
  exportSeries() {
    const series = this.getSeries().map((serie) => serie.export());
    return this._applyYAxisIndexes(series);
  }

  getSerieName(id) {
    const serie = this.getSerie(id);
    return serie ? serie.name : undefined;
  }

  getTooltipInfos(index) {
    return _.compact(this.getSeries().map((serie) => serie.getPoint(index).info));
  }

  getLabel(index) {
    return this.getSeries()[0].getPoint(index).label;
  }

  getFormattedValue(serieId, index) {
    return this.getSerie(serieId).getPoint(index).formattedValue;
  }

  hasData() {
    return _.some(this._getAllValues(), (value) => value != null);
  }

  isLegendEnabled() {
    return this.getSeries().length > 1;
  }

  isLogarithmic() {
    if (!this.options.get('logarithmic')) {
      return false;
    }
    const logarithmicBeyond = this.options.get('logarithmic_beyond');
    if (!logarithmicBeyond) {
      // there is no value beyond condition
      return true;
    }
    return _.some(this._getAllValues(), (value) => value != null && Math.abs(value) > Math.abs(logarithmicBeyond));
  }

  hasZeroOrNegativeValues() {
    return _.some(this._getAllValues(), (value) => value <= 0);
  }

  hasCustomYAxes() {
    return this.getCustomYAxes().length > 0;
  }

  getCustomYAxes() {
    if (this._axes) {
      // there is a cached result
      return this._axes;
    }

    const axesOption = this.options.get('yAxes');
    if (!axesOption || !axesOption.length) {
      this._axes = [];
      return this._axes;
    }

    const axes = _.cloneDeep(axesOption);
    const generateTitle = (axis) => axis.ids.map((id) => this.getSerieName(id)).join(', ');

    axes.map((axis) => {
      axis.opposite = axis.opposite || false;
      if (axis.title !== false) {
        axis.title = axis.title || generateTitle(axis);
      }
      return axis;
    });

    if (axes.length === 1 && axes[0].opposite) {
      // there is one opposite axis, add a default y-axis on the left
      axes.unshift({ ids: [], opposite: false, title: false });
    }

    this._axes = axes;
    return this._axes;
  }

  _applyYAxisIndexes(series) {
    if (!this.hasCustomYAxes()) {
      return series;
    }

    series.forEach((serie) => {
      const axisIndex = this.getCustomYAxes().findIndex((axis) => axis.ids.indexOf(serie.id) !== -1);

      if (axisIndex !== -1) {
        serie.yAxis = axisIndex;
      }
    });

    return series;
  }

  _getAllValues() {
    return [].concat.apply([], this.getSeries().map((serie) => serie.values()));
  }
}
