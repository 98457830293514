<template>
  <div class="nav-horizontal nav-internal">
    <div class="container p-0 grid-xl">
      <ul class="nav" ref="horizontalNav">
        <li v-for="item in items"
            :key="item.id"
            :class="['nav-item', {active: item.id == activeId}]"
            :ref="el => { horizontalItemRefs[item.id] = el }">
          <a v-scroll-to="scrollOptions(item, -115)">
            <i v-if="item.icon" :class="iconClass(item)"></i>
            <span class="nav-item-text">{{ item.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="off-canvas-sidebar" id="sidebar">
    <ul class="nav">
      <li v-for="item in items" :key="item.id" :class="['nav-item', {active: item.id == activeId}]">
        <a v-scroll-to="scrollOptions(item, -90)">
          <i v-if="item.icon" :class="iconClass(item)"></i>
          <span class="nav-item-text">{{ item.name }}</span>
        </a>
      </li>
    </ul>

    <ad v-if="ad" :location="ad"></ad>

  </div>
</template>

<script>
import * as _ from 'lodash';
import VueScrollTo from 'vue-scrollto';
import NavigationObserver from '../utility/navigation-observer';
import Ad from './ad.vue';

export default {
  props: {
    items: { type: Array, required: true },
    ad: { type: String }
  },
  data() {
    return {
      navObserver: null,
      horizontalItemRefs: {}
    };
  },
  mounted() {
    this.navObserver = new NavigationObserver(_.map(this.items, 'id'));
    this.navObserver.init();
    this.onNavChangeDelayed = _.debounce(this.handleNavChange, 500);
  },
  methods: {
    iconClass(item) {
      return [`icon-${item.icon}`, 'mr-2', 'icon-1x'];
    },
    scrollOptions(item, offset = 0) {
      return { el: `#${item.id}`, offset };
    },
    handleNavChange() {
      const { horizontalNav } = this.$refs;
      const activeItem = this.horizontalItemRefs[this.activeId];

      if (!activeItem || horizontalNav.offsetParent === null) {
        return;
      }

      VueScrollTo.scrollTo(activeItem, 500, { container: horizontalNav, x: true, y: false });
    }
  },
  computed: {
    activeId() {
      return this.navObserver && this.navObserver.activeId ? this.navObserver.activeId : null;
    }
  },
  watch: {
    activeId() {
      this.onNavChangeDelayed();
    }
  },
  components : { Ad }
};

</script>
