export const hoverClass = {
  mounted(el, binding) {
    const className = binding.value;
    const delay = 300;
    let timeout;

    // Mouse enter event
    el.addEventListener('mouseenter', () => {
      clearTimeout(timeout);
      el.classList.add(className);
    });

    // Mouse leave event with delay
    el.addEventListener('mouseleave', (event) => {
      // Check if the mouse is moving towards the dropdown
      const { relatedTarget } = event;
      if (!el.contains(relatedTarget)) {
        // Add delay before removing the class to avoid closing while hovering over dropdown
        timeout = setTimeout(() => {
          el.classList.remove(className);
        }, delay); // Adjust delay as needed
      }
    });
  }
};

export const clickToggleClass = {
  mounted(el, binding) {
    const className = binding.value;

    el.addEventListener('click', (e) => {
      e.preventDefault();
      el.classList.toggle(className);
    });
  }
};