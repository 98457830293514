<template>
  <div class="empty empty-darker">
    <div class="empty-icon">
      <i :class="iconClass"></i>
    </div>
    <p class="empty-title h5">
      {{ text }}
    </p>

    <p v-if="subtitle" class="empty-subtitle">
      {{ subtitle}}
    </p>

    <div class="empty-action">
      <a class="btn btn-outline-primary mr-3" href="/account/sign_in">Sign in</a>
      <span class="text-small">OR</span>
      <a class="btn btn-primary ml-3" href="/account/sign_up">Sign up for free</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: 'lock' },
    text: { type: String, default: 'Please sign in to access this data for free' },
    subtitle: { type: String, default: null }
  },
  computed: {
    iconClass() {
      return `icon-2x icon-${this.icon}`;
    }
  }
};
</script>
