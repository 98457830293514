export default class {
  constructor(options) {
    this._options = options;
  }

  export() {
    return {
      value: this._options.value,
      width: this.width,
      color: this._options.color,
      dashStyle: this.style,
      zIndex: 10
    };
  }

  get width() {
    return this._options.width || 2;
  }

  get style() {
    const styleMap = { solid: 'Solid', dash: 'ShortDash' };
    return styleMap[this._options.style] || 'ShortDash';
  }
}
