<template>
  <remote-search @item-select="itemSelected" path="/stocks/search" placeholder="Search stocks...">
    <template #item="{ticker, name, url}">
      <a :href="url">
        <span class="label label-secondary">{{ ticker }}</span> - {{ name }}
      </a>
    </template>
  </remote-search>
</template>

<script>

import RemoteSearch from "../remote-search.vue";

export default {
  methods: {
    itemSelected(item) {
      window.location = item.url;
    }
  },
  components: { RemoteSearch }
};

</script>
