// code from: https://github.com/ndelvalle/v-click-outside/issues/238#issuecomment-713872610
export default {
  beforeMount: function (el, binding) {
    // Define clickHandler
    const clickHandler = event => {
      if (!el.contains(event.target) && el !== event.target) {
        // as we are attaching an click event listen to the document (below)
        // ensure the events target is outside the element or a child of it
        binding.value(event); // before binding it
      }
    };
    // attached the handler to the element so we can remove it later easily
    el.__clickOutsideHandlerler;

    // attaching clickHandler to a listener on the document here
    document.addEventListener("click", clickHandler);
  },
  unmounted: function (el) {
    // Remove Event Listener
    document.removeEventListener("click", el.__clickOutsideHandler__);
  }
};
