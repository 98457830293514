<template>
  <div v-on-viewport-enter:[lazyLoad].once="initChart" :class="{'lazy-load-chart': lazyLoad }">
    <div ref="chart"></div>
  </div>
</template>

<script>
import baseChartMixin from '../../mixins/charts/base-chart-mixin';
import OnViewportEnter from '../../directives/on-viewport-enter';

export default {
  props: {
    type: { type: String, default: 'column' },
    lazyLoad: { type: Boolean, default: false }
  },
  mounted() {
    if (!this.lazyLoad) this.initChart();
  },
  methods: {
    chartOptions() {
      return {
        chart: {
          type: this.type
        },
        plotOptions: {
          series: {
            connectNulls: this.type !== 'area'
          }
        }
      };
    }
  },
  mixins: [baseChartMixin],
  directives: { OnViewportEnter }
};

</script>
