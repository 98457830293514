/**
 * Extract the error message from the response.
 * First check for a single error message, then check for an array of errors.
 * If array of errors, return the first error.
 * @param error - the error object
 * @returns {*|string} - error message
 */
function extractError(error) {
  if (error.response.data.error) {
    return error.response.data.error;
  }

  if (error.response.data.errors) {
    return error.response.data.errors[0];
  }

  // only log if it is an unexpected error
  console.error(error);
  return 'An error occurred. Please try again or contact support for assistance.';
}

export { extractError };