export default class {
  constructor(options, itemCount, screen) {
    this._isScrollable = !!options.enabled;
    this._activationSize = options.size;
    this._itemCount = itemCount;
    this._screen = screen;
    this._maxItemsToShow = options.max_items;
  }

  isEnabled() {
    return this._isScrollable && this._isActivationItemCountMet() && this._isActivationSizeMet();
  }

  getFirstVisibleIndex() {
    if (!this._maxItemsToShow || this._maxItemsToShow > this._itemCount) return 0;

    return this._itemCount - this._maxItemsToShow || 0;
  }

  _isActivationItemCountMet() {
    return !this._maxItemsToShow || this._itemCount > this._maxItemsToShow;
  }

  _isActivationSizeMet() {
    return !this._activationSize || this._screen.isEqualOrSmallerThan(this._activationSize);
  }
}
