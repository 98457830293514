import * as Highcharts from 'highcharts';
import Stock from 'highcharts/modules/stock';
import NoData from 'highcharts/modules/no-data-to-display';
import * as _ from 'lodash';
import chartOptions from '../../utility/charts/chart-options';
import allowNegativeLogarithmic from '../../utility/charts/negative-logarithmic';
import Config from '../../utility/charts/config';

NoData(Highcharts);
Stock(Highcharts);

Highcharts.setOptions(chartOptions);
Highcharts.seriesTypes.line.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline;

allowNegativeLogarithmic(Highcharts);

export default {
  props: {
    data: { type: Object, required: true }
  },
  data() {
    return {
      chart: null,
      config: null,
      shouldAdjustAxes: false
    };
  },
  methods: {
    initChart() {
      this.config = new Config(this.data, this.$screen);
      this.draw();
      this.adjustAxes();

      this.$watch('options', (newVal, oldVal) => {
        // additionally check if the value has changed as sometimes the watcher is fired
        // when there is no actual change to the options object (usually when the dependant $screen is changed)
        if (!_.isEqual(newVal, oldVal)) {
          this.redraw();
        }
      }, { deep: true });
    },

    getTooltipOptions() {
      const self = this;
      return {
        shared: true,
        // this is so it doesn't show over the plot lines
        outside: true,
        formatter() {
          const index = this.x;
          const label = self.config.getLabel(index);

          let result = this.points.reduce((s, point) => `${s}<br/>
              <span style="color:${point.color}">●</span> ${point.series.name}:
              ${self.config.getFormattedValue(point.series.userOptions.id, index)}`, `<b>${label}</b>`);

          const infos = self.config.getTooltipInfos(index);
          if (infos.length) {
            result += `<br>${infos.join('<br>')}`;
          }

          return result;
        }
      };
    },

    getYAxisOptions() {
      let result;

      if (this.config.hasCustomYAxes()) {
        result = this.config.getCustomYAxes().map((axis) => {
          const options = _.pick(axis, 'opposite', 'visible');
          options.title = axis.title ? { text: axis.title } : { enabled: false };

          if (axis.opposite) {
            options.softMin = 0;
            options.gridLineWidth = 0;
          }

          if (axis.format) {
            options.labels = { format: axis.format };
          }

          return options;
        });
      } else {
        result = {
          plotLines: this.config.getHorizontalLines()
        };

        if (this.config.isLogarithmic()) {
          result.type = 'logarithmic';
          result.title = { text: '(log)' };
          if (this.config.hasZeroOrNegativeValues()) {
            result.custom = { allowNegativeLog: true };
          }
        } else {
          result.title = { enabled: false };
        }
      }
      return result;
    },

    adjustAxes() {
      if (this.config.getCustomYAxes().length !== 2) {
        return;
      }

      const extremes = this.chart.yAxis[0].getExtremes();
      if (extremes.dataMax != null && extremes.max
        && Math.abs((extremes.max - extremes.dataMax) / extremes.max) >= 0.3) {
        // there is a lot of gap/padding (more than 30%) on top of the main axis
        // set the manual padding (example BA stock)
        this.shouldAdjustAxes = true;
        this.redraw();
      }
    },

    draw() {
      const drawChart = this.config.getFormat() === 'datetime' ? Highcharts.stockChart : Highcharts.chart;
      const el = this.$refs.chart || this.$el;

      this.chart = drawChart.call(null, el, this.options);
      if (!this.config.hasData()) {
        this.chart.showNoData('No data to display');
      }
    },

    redraw() {
      this.draw();
    }
  },
  computed: {
    options() {
      if (!this.config) return null;

      let defaults = {
        chart: {
          type: 'column',
          height: this.config.getHeight()
        },
        title: {
          text: this.config.getTitle()
        },
        series: this.config.exportSeries(),
        legend: {
          enabled: this.config.isLegendEnabled()
        },
        xAxis: {
          type: this.config.getFormat()
        },
        yAxis: this.getYAxisOptions(),
        tooltip: this.getTooltipOptions()
      };

      if (this.config.getFormat() === 'datetime') {
        // hide the HighStock elements
        defaults = _.merge(defaults, {
          navigator: { enabled: false },
          scrollbar: { enabled: false },
          rangeSelector: { enabled: false },
          // keep distance between points the same
          xAxis: { ordinal: true }
        });
      }

      if (this.config.scrollbar.isEnabled()) {
        defaults.xAxis.scrollbar = {
          enabled: true,
          showFull: false
        };
        defaults.xAxis.min = this.config.scrollbar.getFirstVisibleIndex();
      }

      const result = _.merge(defaults, this.chartOptions());

      if (this.shouldAdjustAxes) {
        const axisOptions = { startOnTick: false, endOnTick: false, maxPadding: 0.1 };
        _.merge(result.yAxis[0], axisOptions);
        _.merge(result.yAxis[1], axisOptions);
      }

      return result;
    }
  }
};
