require('intersection-observer');

const ROOT_MARGIN = '-70px 0px 70px 0px';
const CALLBACK_DELAY = 300;

/**
 * Runs a callback function when an element enters the viewport.
 * Set the callback as a value
 * Supported modifiers: "once" will run the callback once
 * Supported arg: enabled, boolean, default is true. This can be used if we need to enable the directive dynamically.
 * Example:
 * v-on-viewport-enter:[true].once="initChart"
 */
export default {
  mounted(el, binding) {
    // can be enabled/disabled by the binding.arg, e.g. v-on-viewport-enter:[false]="callbackName"
    if (binding.arg === false) return;

    const callback = binding.value;
    const runOnce = binding.modifiers.once;
    let timeout = null;

    const onObserverChange = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // we need a timeout so that we don't detect all sections we scroll through
          // when we scroll fast (e.g. when we use the internal page navigation)
          timeout = setTimeout(() => {
            callback();
            if (runOnce) observer.disconnect();
          }, CALLBACK_DELAY);
        } else {
          clearTimeout(timeout);
        }
      });
    };

    const observer = new IntersectionObserver(onObserverChange, {
      rootMargin: ROOT_MARGIN
    });
    observer.observe(el);
  }
};
