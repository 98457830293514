<template>
  <div></div>
</template>

<script>
import Highcharts from 'highcharts';
import PieConfig from '../../utility/charts/pie-config';
import chartOptions from '../../utility/charts/chart-options';

Highcharts.setOptions(chartOptions);

export default {
  props: {
    data: { type: Object, required: true },
    height: {}
  },
  mounted() {
    this.initChart();
  },

  methods: {
    initChart() {
      this.chart = Highcharts.chart(this.$el, this.chartOptions());
    },
    chartOptions() {
      const self = this;
      this.config = new PieConfig(this.data);

      const options = {
        chart: {
          type: 'pie'
        },
        title: {
          text: this.config.getTitle()
        },
        series: [this.config.exportSerie()],
        tooltip: {
          formatter() {
            return self.formatTooltip(this);
          }
        }
      };

      if (this.height) {
        options.chart.height = this.height;
      }

      if (this.config.shouldCenterTitle()) {
        options.title.verticalAlign = 'middle';
        options.title.floating = true;
      }

      return options;
    },
    formatTooltip(item) {
      const { index } = item.point;
      const label = this.config.getLabel(index);
      const formattedValue = this.config.getFormattedValue(index);

      if (formattedValue) {
        return `${label}: ${formattedValue}`;
      }

      return false;
    }
  }
};

</script>
