<template>
  <div v-if="enabled && ad" class="ad mt-7">
    <!--  Ad content  -->
  </div>
</template>

<script>
import * as _ from 'lodash';

export default {
  props: {
    location: { type: String, required: true }
  },
  data() {
    return {
      enabled: false, // use this to temporarily disable all ads on the site
      ad: null,
      adName: null,
      ads: window.adConfig.ads,
      adLocations: window.adConfig.locations
    };
  },
  mounted() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});

    const adMap = this.adLocations[this.location];

    if (adMap) {
      this.adName = Array.isArray(adMap) ? _.sample(adMap) : adMap;
      this.ad = this.adName && this.ads[this.adName];
    }
  }
};

</script>
