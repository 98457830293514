<template>
  <div class="tabs">
    <div class="tabs-header">
      <span v-if="title" class="tab-title">{{ title }}</span>
      <ul class="tab-choices tab" v-if="tabType=='tab'">
        <li v-for="(tab, index) in tabs"
            :key="tab.title"
            @click.stop.prevent="selectedIndex = index"
            :class="['tab-item', {'active': (index == selectedIndex)}]">
          <button class="btn btn-link">{{ tab.title }}</button>
        </li>
      </ul>
      <div class="tab-choices tab-menu" v-if="tabType=='dropdown'">
        <dropdown :items="tabItems" v-model="selectedIndex" position="right"></dropdown>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Dropdown from './dropdown.vue';

export default {
  props: {
    title: { type: String },
    type: { type: String, default: 'tab' } // tab or dropdown
  },
  data() {
    return {
      selectedIndex: 0,
      tabs: []
    };
  },
  mounted() {
    this.setTabSelected(0);
  },
  methods: {
    setTabSelected(index) {
      this.tabs.forEach((tab, i) => {
        tab.isActive = (i === index);
      });
    }
  },
  computed: {
    selectedTab() {
      return this.tabs[this.selectedIndex];
    },
    selectedName() {
      return this.selectedTab.title ? this.selectedTab : null;
    },
    tabItems() {
      return this.tabs.map((tab) => tab.title);
    },
    tabType() {
      return this.$screen.sm ? 'dropdown' : this.type;
    }
  },
  watch: {
    selectedIndex(value) {
      this.setTabSelected(value);
    }
  },
  components: { Dropdown }
};
</script>
