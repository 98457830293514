<template>
  <div>
    <div :class="['dropdown dropdown-js', {'active': visible, 'dropdown-right': position==='right'}]">
      <div class="btn-group" @click="visible = !visible">
        <a class="btn btn-primary">{{ selectedName }}</a>
        <a class="btn btn-primary dropdown-toggle" tabindex="0">
          <i class="icon-caret"></i>
        </a>
        <ul class="menu">
          <li v-for="(name, index) in items" :key="name" class="menu-item">
            <button @click.stop.prevent="selectItem(index)" :class="['btn', 'btn-link', {'active': (index == modelValue)}]">
              {{ name }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { required: true },
    items: { type: [Array, Object], required: true },
    position: { type: String, default: 'left' }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    selectItem(index) {
      this.$emit('update:modelValue', index);
      this.visible = false;
    }
  },
  computed: {
    selectedName() {
      return this.modelValue !== undefined ? this.items[this.modelValue] : 'Select';
    }
  }
};
</script>
