<template>
  <div class="watchlist">
    <div v-show="isLoadingStocks" class="empty loading loading-lg" />

    <table v-if="stocks.length"
           class="table table-striped">
      <thead>
        <tr>
          <th>Ticker</th>
          <th>Name</th>
          <th>Market cap</th>
          <th>P/E ratio</th>
          <th>Dividend yield</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="stock in stocks" :key="stock.key">
          <td>{{ stock.ticker }}</td>
          <td>
            <a :href="stock.url">{{ stock.name }}</a>
          </td>
          <td>{{ stock.market_cap}}</td>
          <td>{{ stock.pe_ratio}}</td>
          <td>{{ stock.dividend_yield}}</td>
          <td class="text-right">
            <a class="icon icon-trash p-2 c-hand text-primary"
               :class="{loading: stock.isBeingRemoved}"
               @click.prevent="removeStock(stock)" />
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="error" class="toast toast-error mt-5">
      {{ error }}
    </div>

    <div v-if="!isLoadingStocks" class="add-stocks">
      <h3 class="h4 mt-5">
        Add stocks
        <span v-show="isLoadingNewStock" class="loading pl-10" />
      </h3>
      <remote-search ref="search"
                     placeholder="Search stocks..."
                     class="stock-search"
                     path="/stocks/search"
                     @item-select="addStock">
        <template #item="slotProps">
          <a class="p-1" :class="{disabled: stockAdded(slotProps)}" @click.prevent="addStock(slotProps)">
            <span class="label label-secondary">{{ slotProps.ticker }}</span> - {{ slotProps.name }}
          </a>
        </template>
      </remote-search>
    </div>
  </div>
</template>

<script>

import * as _ from 'lodash';
import RemoteSearch from '../remote-search.vue';
import { extractError } from "../../utility/remote-error";

export default {
  components: { RemoteSearch },
  props: {
    id: { type: Number, required: true }
  },
  data() {
    return {
      stocks: [],
      error: null,
      isLoadingStocks: false,
      isLoadingNewStock: false
    };
  },
  mounted() {
    this.fetchStocks();
  },
  methods: {
    fetchStocks() {
      this.isLoadingStocks = true;
      this.error = null;
      this.$axios.get(`/account/watchlists/${this.id}/stocks`)
          .then((response) => {
            this.stocks = response.data;
          })
          .catch(this.handleError)
          .finally(() => {
            this.isLoadingStocks = false;
          });
    },

    addStock(stock) {
      if (this.stockAdded(stock)) {
        return;
      }
      this.error = null;
      this.isLoadingNewStock = true;

      this.$axios.post(`/account/watchlists/${this.id}/stocks`, { key: stock.key })
          .then((response) => {
            this.stocks = response.data;
            this.$refs.search.clear();
          })
          .catch(this.handleError)
          .finally(() => {
            this.isLoadingNewStock = false;
          });
    },

    removeStock(stock) {
      this.error = null;
      stock.isBeingRemoved = true;

      this.$axios.delete(`/account/watchlists/${this.id}/stocks/${stock.key}`)
          .then((response) => {
            this.stocks = response.data;
          })
          .catch(this.handleError)
          .finally(() => {
            stock.isBeingRemoved = false;
          });
    },

    stockAdded(stock) {
      return _.some(this.stocks, ['key', stock.key]);
    },

    handleError(error) {
      this.error = extractError(error);
    }
  }
};

</script>
