import initializeVue from './utility/initialize-vue';
import Tabs from "./components/elements/tabs.vue";
import Watchlist from "./components/account/watchlist.vue";
import FeatureRequest from "./components/account/feature-request.vue";

initializeVue(
  'account-page',
  {
    components: { Tabs, Watchlist, FeatureRequest }
  }
);
