import './header';
import './stock-profile';
import './market-analysis';
import './term';
import './account';
import Rails from '@rails/ujs';
import initSentry from './utility/init-sentry';

Rails.start();

require('turbolinks').start();
require('@rails/activestorage').start();
// require('channels');

if (APP_ENV === 'production') {
  initSentry();
}

// add a scrolled class to body after scroll
window.addEventListener('scroll', () => {
  if (window.top.scrollY > 100) {
    document.body.classList.add('scrolled');
  } else {
    document.body.classList.remove('scrolled');
  }
}, { passive: true });
