import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

export default () => {
  let onMouseOutLogged = false;
  const isOnMouseOutError = (error) => error && error.message && error.message.match(/onMouseOut is not a function/i);

  Sentry.init({
    dsn: 'https://5a6cd07008624fd79c4574490f0da58f@o416897.ingest.sentry.io/5314081',
    beforeSend(event, hint) {
      const error = hint.originalException;

      // log the t.reset r.onMouseOut is not a function error only once per user per page load
      if (isOnMouseOutError(error)) {
        if (onMouseOutLogged) return null;

        onMouseOutLogged = true;
      }
      return event;
    },
    integrations: [CaptureConsole({ levels: ['error'] })]
  });
};
