<template>
  <div class="tab-content" v-show='isActive'>
    <slot></slot>
  </div>
</template>

<script>
export default {
  emits: ['activated', 'deactivated'],
  props: {
    title: { type: String }
  },
  data() {
    return {
      isActive: true
    };
  },
  created() {
    this.$parent.tabs.push(this);
  },
  watch: {
    isActive() {
      const event = this.isActive ? 'activated' : 'deactivated';
      this.$emit(event);
    }
  }
};
</script>
