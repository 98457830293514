import * as _ from 'lodash';

require('intersection-observer');

export default class {
  constructor(itemIds) {
    this.activeSections = [];
    this.activeId = null;
    this.itemIds = itemIds;
  }

  init() {
    const observer = new IntersectionObserver(this.onObserverChange.bind(this), {
      rootMargin: '-10% 0% -50% 0%'
    });

    this.itemIds.forEach((id) => {
      const navSection = document.querySelector(`#${id}`);
      if (navSection) {
        observer.observe(navSection);
        // attach to navDone even that is fired by vue-scroll-to
        navSection.addEventListener('navDone', () => {
          this.markAsActive(id);
        });
      }
    });
  }

  markAsActive(id) {
    this.activeId = id;
  }

  onObserverChange(entries) {
    entries.forEach((entry) => {
      const id = entry.target.getAttribute('id');

      if (entry.isIntersecting) {
        this.activeSections.push(id);
        this.markAsActive(id);
      } else {
        _.remove(this.activeSections, (el) => el === id);
        if (this.activeSections.length) {
          this.markAsActive(this.activeSections[0]);
        }
      }
    });
  }
}
