<template>
  <div />
</template>

<script>
import baseChartMixin from '../../mixins/charts/base-chart-mixin';

export default {
  mounted() {
    this.initChart();
  },
  methods: {
    chartOptions() {
      return {
        yAxis: {
          startOnTick: false,
          endOnTick: false,
          maxPadding: 0.02,
          labels: {
            enabled: false
          },
          plotLines: [{
            color: '#e9e7e5',
            width: 3,
            value: 0
          }],
          title: {
            enabled: false
          }
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderColor: null
          }
        }
      };
    }
  },
  mixins: [baseChartMixin]
};
</script>
