import { hoverClass } from './apply-class-on-action.js';

export default {
  mounted(el, binding, vnode) {
    const { mobile } = binding.modifiers;

    if (mobile) {
      const toggleArrow = el.querySelector('.icon-chevron-right');
      const openAttr = 'open';

      if (toggleArrow) {
        toggleArrow.addEventListener('click', () => {
          if (el.hasAttribute(openAttr)) {
            el.removeAttribute(openAttr);
          } else {
            el.setAttribute(openAttr, '');
          }
        });
      }
    } else {
      hoverClass.mounted(el, { value: 'active' }, vnode);
    }
  }
};
