const BREAKPOINTS = {
  xs: 480,
  sm: 600,
  md: 840,
  lg: 960,
  xl: 1200,
  xxl: 1440
};

const COLORS = {
  colorPrimary: '#0052CC',
  colorLight: '#fff',
  colorPrimaryDark: '#003e99',
  colorBodyFont: '#42526E',
  colorBgBaseLight: '#fbfbfc',
  colorBgBase: '#f5f6f8',
  colorBgBaseDark: '#eceef2',
  colorBgBaseDark2: '#dde0e7',
  colorBgBaseDarker: '#b9c0ce',
  bgColorDark: '#f0f1f4',
  colorInkLight: '#667189',
  colorInkLighter: '#acb3c2',
  colorInkLighter2: '#8691a5',
  borderColorLight: '#e6e9ee',
  borderColorLight2: '#d7dbe3',
  borderColorLight3: '#c8ced9',
};

export { BREAKPOINTS, COLORS };
