<template>
  <div class="feature-request card">
    <div class="card-body">
      <h4>{{ title }}</h4>
      <p>
        {{ contentFirstPart }}<span v-if="expandable && expanded">{{ contentSecondPart }}</span>
        <span v-if="expandable && !expanded" @click="expanded = true" class="c-hand"> ...
          <span class="label label-secondary pb-1">&raquo;</span>
        </span>
        <span v-if="expandable && expanded" @click="expanded = false" class="c-hand">
          <span class="label label-secondary pb-1">&laquo;</span>
        </span>
      </p>
    </div>
    <div class="card-footer pt-0">
      <button v-if="published" class="px-3 c-hand btn badge" :class="{'btn-primary': localLiked}"
              :data-badge="localUpvotes"
              @click="doOnUpvoteClick">
        <i class="icon icon-heart" :class="{'loading': isLoading}"></i>
      </button>

      <span v-if="!published" class="label label-rounded label-warning">
        pending moderation
      </span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    id: { type: Number, required: true },
    title: { type: String, required: true },
    liked: { type: Boolean, required: true },
    upvotes: { type: Number, required: true },
    published: { type: Boolean, required: true }
  },
  data() {
    return {
      localLiked: this.liked,
      localUpvotes: this.upvotes,
      isLoading: false,
      maxContentLength: 100,
      expanded: false
    };
  },
  methods: {
    doOnUpvoteClick() {
      this.isLoading = true;
      this.$axios.post(`/feature-requests/${this.id}/upvotes`)
          .then((response) => {
            this.localLiked = response.data.liked;
            this.localUpvotes = response.data.upvotes;
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
  },
  computed: {
    fullContent() {
      return this.$slots.default()[0].children.trim();
    },
    contentFirstPart() {
      return this.fullContent.slice(0, 100);
    },
    contentSecondPart() {
      return this.fullContent.slice(100);
    },
    expandable() {
      return this.fullContent.length > this.maxContentLength;
    }
  }
};

</script>
