import { COLORS } from '../../utility/variables';

export default {
  lang: {
    numericSymbols: ["k", "M", "B", "T", "P", "E"],
    // hide the zoom text from the price chart
    rangeSelectorZoom: ''
  },
  colors: ['#1865d8', '#31b683', '#ffb312', '#e14337', '#7f70bb', '#f3c500', '#FFB800', '#DD2F38', '#FE2E55'],
  chart: {
    backgroundColor: COLORS.colorLight,
    spacingTop: 30,
    spacingBottom: 5,
    spacingLeft: 5,
    spacingRight: 5,
    style: {
      fontFamily: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif'
    }
  },
  tooltip: {
    backgroundColor: '#363c49',
    borderWidth: 0,
    borderRadius: 5,
    padding: 10,
    shadow: false,
    style: {
      color: '#f5f4f3'
    }
  },
  xAxis: {
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e %b',
      week: '%e %b',
      month: '%b \'%y',
      year: '%Y'
    },
    labels: {
      style: {
        color: COLORS.colorInkLighter2
      }
    },
    // hides the ticks from the price chart
    tickWidth: 0,
    uniqueNames: false,
    scrollbar: {
      trackBackgroundColor: COLORS.colorBgBase,
      barBackgroundColor: COLORS.colorBgBaseDark2,
      barBorderColor: COLORS.borderColorLight3,
      buttonBackgroundColor: COLORS.colorBgBaseDark,
      buttonBorderColor: COLORS.borderColorLight,
      buttonArrowColor: COLORS.colorBodyFont,
      rifleColor: COLORS.colorBodyFont,
      buttonBorderRadius: 2,
      barBorderRadius: 2,
    }
  },
  yAxis: {
    gridLineColor: '#f2f6fb',
    labels: {
      align: 'left',
      x: 0,
      y: -2,
      useHTML: true,
      overflow: 'allow',
      reserveSpace: true,
      style: {
        color: COLORS.colorInkLighter
      }
    }
  },
  credits: {
    enabled: false
  },

  plotOptions: {
    area: {
      color: '#b2d4ff',
      lineColor: '#005EEF',
      marker: {
        radius: 3,
        fillColor: '#005EEF'
      }
    },
    pie: {
      innerSize: 80,
      dataLabels: {
        enabled: false
      }
    },
    series: {
      states: {
        inactive: {
          opacity: 1
        }
      },
      marker: {
        radius: 3
      }
    },
    spline: {
      marker: {
        symbol: 'circle'
      }
    }
  },

  // price chart range selector styles
  rangeSelector: {
    inputStyle: {
      color: COLORS.colorBodyFont
    },
    buttonTheme: {
      fill: COLORS.colorBgBaseLight,
      r: 3,
      style: {
        color: COLORS.colorBodyFont,
        fontSize: '13px'
      },
      states: {
        select: {
          fill: COLORS.bgColorDark,
          style: {
            color: COLORS.colorPrimary
          }
        },
        hover: {
          fill: COLORS.bgColorDark,
          style: {
            color: COLORS.colorPrimary
          }
        }
      }
    }
  }
};
