import RemoteChart from './components/charts/remote-chart.vue';
import RemoteChartTabs from './components/charts/remote-chart-tabs.vue';
import Ad from './components/ad.vue';
import initializeVue from './utility/initialize-vue';

initializeVue(
  'market-analysis-page',
  {
    components: {
      RemoteChart, RemoteChartTabs, Ad
    }
  }
);
