<template>
  <span>
    <span v-if="!visible" v-show="!visible">...</span>
    <span class="loading ml-3 mr-3" v-if="loading"></span>
    <button v-if="!visible && !loading" @click="showDetails()" class="btn btn-link ml-1 mt-0">Show more »</button>
    <span v-if="visible" class="read-more-text">
      {{ text }}
    </span>
    <button v-if="visible" @click="visible = false" class="btn btn-link ml-1 mt-0">« Show less</button>
  </span>
</template>

<script>
export default {
  props: {
    remotePath: { type: String, required: true }
  },
  data() {
    return {
      loading: false,
      visible: false,
      text: '',
      error: ''
    };
  },
  methods: {
    async showDetails() {
      if (!this.text) {
        this.loading = true;

        try {
          const response = await this.$axios.get(this.remotePath);
          this.text = response.data.description;
          this.visible = true;
        } catch (_error) {
          this.error = true;
        } finally {
          this.loading = false;
        }
      } else {
        this.visible = true;
      }
    }
  }
};
</script>
