import * as _ from 'lodash';
import { reactive } from 'vue';
import { BREAKPOINTS } from '../utility/variables';

let screen = null;

export function getScreen() {
  if (screen === null) {
    screen = reactive({
      breakpointOrder: Object.keys(BREAKPOINTS).sort((a, b) => BREAKPOINTS[a] - BREAKPOINTS[b]),

      isEqualOrSmallerThan(size) {
        if (!this.size) return false;

        return this.breakpointOrder.indexOf(this.size) <= this.breakpointOrder.indexOf(size);
      },

      /**
       * Retrieves an option value based on current screen size.
       * @param options a hash of options where each key represents the maximum screen size for which the value can be applied
       * and value is the corresponding value. Optionally a default value can be also provided (with default: key)
       * @returns the value that is applicable for the current screen size. If value is not provided
       * for current size, it will return the value of the closest size that is applicable for current screen size.
       * Example: with the following options:
       * { sm: 'foo', lg: 'bar', default: 'baz' }
       * it will return:
       * - 'foo' for screen size xs and sm
       * - 'bar' for screen size md and lg
       * - 'baz' for all other sizes
       */
      findOptionForCurrentSize(options) {
        if (_.isEqual(Object.keys(options), ['default']) || !this.size) return options.default;

        // find the first configured size breakpoint that is applicable
        // e.g. if current screen size is md, applicable breakpoint sizes would be md, lg, xl, xxl
        const sizeIndex = this.breakpointOrder.indexOf(this.size);
        const sizeKey = screen.breakpointOrder.slice(sizeIndex).find((sizeName) => options[sizeName] !== undefined);

        return sizeKey ? options[sizeKey] : options.default;
      }
    });

    Object.keys(BREAKPOINTS).forEach((key) => {
      const width = BREAKPOINTS[key];
      const query = window.matchMedia(`(max-width: ${width}px)`);

      // sets the name of the smallest breakpoint that is true
      // it can be undefined on very large screens
      const setCurrentSize = () => {
        screen.size = screen.breakpointOrder.find((breakpoint) => screen[breakpoint] === true);
      };

      const setSize = (() => (q) => {
        screen[key] = q.matches;
        setCurrentSize();
      })();

      setSize(query);

      query.addListener(setSize);
    });
  }
  return screen;
}

export default {
  install: (app) => {
    app.config.globalProperties.$screen = getScreen();
  }
};
