import { defineStore } from 'pinia';
import axios from 'axios';
import _ from 'lodash';
import { extractError } from '../utility/remote-error';

export let useWatchlistWidgetStore = defineStore('watchlistWidget', {
  state() {
    return {
      watchlists: [],
      dataLoaded: false,
      loading: false,
      newWatchlistName: '',
      error: null
    };
  },

  actions: {
    fill(stockKey) {
      this._beforeAction();

      axios.get(`/account/watchlists/stocks/${stockKey}`)
        .then((response) => {
          this.watchlists = response.data;
          this.dataLoaded = true;
        })
        .catch(this._handleError)
        .finally(() => { this.loading = false; });
    },

    addStock(watchlistId, stockKey) {
      this._beforeAction();

      axios.post(`/account/watchlists/${watchlistId}/stocks`, { key: stockKey })
        .then(() => {
          const watchlist = _.find(this.watchlists, { id: watchlistId });
          watchlist.has_stock = true;
        })
        .catch(this._handleError)
        .finally(() => { this.loading = false; });
    },

    removeStock(watchlistId, stockKey) {
      this._beforeAction();

      axios.delete(`/account/watchlists/${watchlistId}/stocks/${stockKey}`)
        .then(() => {
          const watchlist = _.find(this.watchlists, { id: watchlistId });
          watchlist.has_stock = false;
        })
        .catch(this._handleError)
        .finally(() => { this.loading = false; });
    },

    createWatchlist() {
      this._beforeAction();

      axios.post('/account/watchlists', { account_watchlist: { name: this.newWatchlistName } })
        .then((response) => {
          this.watchlists.push(response.data);
          this.newWatchlistName = '';
        })
        .catch(this._handleError)
        .finally(() => { this.loading = false; });
    },

    _beforeAction() {
      this.loading = true;
      this.error = null;
    },

    _handleError(error) {
      this.error = extractError(error);
    }
  }
});
