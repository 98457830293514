<template>
  <button class="btn btn-link" @click="toggle">
    <i :class="iconClass"></i>
  </button>
</template>

<script>
export default {
  props: {
    modelValue: { type: Boolean, required: true },
    icon: { type: String, required: true },
    iconSize: { type: String, default: '1x5' }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    iconClass() {
      const classes = [`icon-${this.iconSize}`];
      if (this.modelValue) {
        classes.push('icon-x');
      } else {
        classes.push(`icon-${this.icon}`);
      }
      return classes;
    }
  },
  methods: {
    toggle() {
      this.$emit('update:modelValue', !this.modelValue);
    }
  }
};
</script>
